<template>
  <section class="login-form">
    <div class="site-inner">
      <FormElement @submit="submitHandler" :class="{ '-has-error': loginStatus === 'ERROR' }">
        <div class="row">
          <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1">
            <InputText name="email"
                       base="loginForm"
                       type="email"
                       class="mb-s"
                       :class="{ '-has-error': $v.user.email.$error}"
                       v-model.trim="$v.user.email.$model">
            </InputText>
            <InputText name="password"
                       base="loginForm"
                       type="password"
                       class="mb-s"
                       :class="{ '-has-error': $v.user.password.$error}"
                       v-model.trim="$v.user.password.$model">
            </InputText>
            <!-- TODO: These checkboxes will be used later
            <InputCheckbox name="checkbox.savePasswordForNextTime"
                           base="loginForm"
                           class="mb-xxs  checkbox-disabled"
                           :disabled="true">
            </InputCheckbox>
            <InputCheckbox name="checkbox.stayLoggedIn"
                           base="loginForm"
                           class="mb-s  checkbox-disabled"
                           :disabled="true">
            </InputCheckbox>
            -->
            <div class="error-msg  mb-s" v-if="loginStatus === 'ERROR'">
              {{ $t('loginForm.result.error') }}
            </div>
            <div :class="{'display-flex  align-items-center  justify-content-between': true}">
              <p class="font-size-xs  mb-0">
                <router-link :to="{ name: 'RequestPassword', params: { lang: this.$i18n.locale } }" v-slot="{ href }" class="-thin  -orange">
                  <AnchorLink :href="href">{{$t('loginForm.requestPassword')}}</AnchorLink>
                </router-link>
              </p>
              <div class="display-flex  justify-content-end" :class="{'justify-content-start': $matchMedia.xs}">
                <Submit class="-thin  -orange"> {{ $t('loginForm.inputs.button.login.label') }}</Submit>
              </div>
            </div>
            <div class="text-center  mt-s  pt-m  border-top  -blue-100">
              <p class="font-size-xs">
                {{ $t('loginForm.inputs.register.label') }}
                <router-link :to="{ name: 'Signup', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }" class="-blue  ml-xs">
                  <Button :href="href" @click.native="navigate" class="-blue">{{ $t('loginForm.inputs.register.link') }}</Button>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </FormElement>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { login } from '@/api/auth.api';

import Button from '@/components/atoms/Button.vue';
import Submit from '@/components/atoms/Submit.vue';
import AnchorLink from '@/components/atoms/Link.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';

export default {
  name: 'LoginForm',
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      loginStatus: false,
    };
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    ...mapActions([
      'handleLogin',
    ]),

    async submitHandler() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loginStatus = 'PENDING';

        try {
          const result = await login(this.user);

          if (result.status === 200) {
            this.handleLogin(result.data)
              .then(() => {
                const { redirect } = this.$router.currentRoute.query;

                if (redirect) {
                  this.$router.push({ path: redirect, params: { lang: this.$i18n.locale } });
                } else {
                  this.$router.push({ name: 'Home', params: { lang: this.$i18n.locale } });
                }
              })
              .catch(() => {
                this.loginStatus = 'ERROR';
              });
          }
        } catch {
          this.loginStatus = 'ERROR';
        }
      }
    },
  },
  mixins: [validationMixin],
  components: {
    Button,
    Submit,
    FormElement,
    InputText,
    AnchorLink,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/login-form.scss"></style>
