<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="login">
      <div v-if="$matchMedia.sm"
           :style="{marginTop: '-40px',height: '40vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%',
           backgroundImage: 'url('+require('@/assets/graphics/login.jpg')+')'}"></div>
      <TwoColumns image="login">
        <template v-slot:text-content>
          <div class="row  mb-m">
            <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1">
              <h1 class="h2 font-weight-normal">{{ $t('loginForm.intro.headline') }}</h1>
              <p class="font-weight-medium  mb-s" v-html="$t('loginForm.intro.text')"></p>
            </div>
          </div>
          <LoginForm></LoginForm>
        </template>
        <template v-slot:image-content></template>
      </TwoColumns>
    </main>
  </div>
</template>

<script>
import TwoColumns from '@/components/elements/TwoColumns.vue';
import LoginForm from '@/components/modules/LoginForm.vue';
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';

export default {
  name: 'Login',
  components: {
    TwoColumns,
    LoginForm,
    HeaderBarPublic,
  },
  metaInfo() {
    return {
      title: 'Login',
    };
  },
};
</script>
